import React from "react";
import { approvalSlice } from "../features/approvals/approvalsSlice";
import { useAppSelector } from "../hooks";
import { Container, Typography, useTheme } from "@mui/material";
import { Stack } from "@mui/system";
import { TopNav } from "../components/top-nav";
import { IStatusTypeStpTypeEnum } from "../services/thumbz-base-api";

interface IStatusRejectedGuardProps {
  children?: React.ReactNode;
}

export const StatusRejectedGuard: React.FC<IStatusRejectedGuardProps> = ({
  children,
}) => {
  const theme = useTheme();
  const { pending, rejected } = useAppSelector(
    approvalSlice.selectors.selectCount
  );
  const status = useAppSelector(approvalSlice.selectors.status);

  if (status?.statusType.stp_type === IStatusTypeStpTypeEnum.IN_REVIEW) {
    return <>{children}</>;
  }

  if (status?.statusType.stp_type === IStatusTypeStpTypeEnum.REQUEST_CHANGED) {
    return (
      <Stack
        flex={1}
        width="100vw"
        height="100vh"
        justifyContent="center"
        alignItems="center"
        spacing={4}
        bgcolor={theme.palette.background.paper}
      >
        <TopNav />
        <img
          alt="Success"
          src={`/assets/icons/icon-warning.png`}
          width={50}
          height={50}
        />
        <Container>
          <Typography variant="h4" gutterBottom textAlign="center">
            Mudanças solicitadas!
          </Typography>
          <Typography variant="body1" textAlign="center">
            O time foi notificado e fará as modificações necessárias em breve.
            <br />
            Enviaremos uma mensagem assim que o material estiver pronto para uma
            nova avaliação.
          </Typography>
        </Container>
      </Stack>
    );
  }

  if (pending > 0 || rejected === 0) {
    return <>{children}</>;
  }

  return (
    <Stack
      flex={1}
      width="100vw"
      height="100vh"
      justifyContent="center"
      alignItems="center"
      spacing={4}
      bgcolor={theme.palette.background.paper}
    >
      <TopNav />
      <img
        alt="Success"
        src={`/assets/icons/icon-warning.png`}
        width={50}
        height={50}
      />
      <Container>
        <Typography variant="h4" gutterBottom textAlign="center">
          Mudanças solicitadas!
        </Typography>
        <Typography variant="body1" textAlign="center">
          O time foi notificado e fará as modificações necessárias em breve.
          <br />
          Enviaremos uma mensagem assim que o material estiver pronto para uma
          nova avaliação.
        </Typography>
      </Container>
    </Stack>
  );
};
